<template>
  <v-container fluid v-if="profile">
    <v-card>
      <v-toolbar dense flat class="teal" dark>
        <v-toolbar-title>Adicionar Ofertas</v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <form>
          <v-col>
            <v-row v-for="(fieldsValue, i) in fieldsValues" :key="i">
              <v-col cols="2">
                <v-select
                  v-model="fieldsValue.osSelected"
                  :items="service"
                  v-if="service"
                  item-text="name"
                  return-object
                  label="Ordem de serviço"
                  required
                  outlined
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                  v-model="fieldsValue.areaSelected"
                  :items="area"
                  item-text="name"
                  item-value="id"
                  v-if="area"
                  label="Área"
                  required
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                  v-model="fieldsValue.periodSelected"
                  label="Período"
                  outlined
                  :items="period"
                  required
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-menu
                  ref="menu1"
                  v-model="fieldsValue.show"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      required
                      outlined
                      label="Data do agendamento"
                      hint="dia/mês/ano"
                      persistent-hint
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :value="dateformat(fieldsValue.dateSelected)"
                      autocomplete="off"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="fieldsValue.dateSelected"
                    no-title
                    :min="dateMin"
                    @input="fieldsValue.show = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" md="2">
                <v-text-field
                  required
                  outlined
                  label="Nº de ofertas"
                  persistent-hint
                  v-model="fieldsValue.quantidade"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="3" md="2" class="d-flex align-center pb-6">
                <v-btn @click="remove(i)" class="error"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <div class="d-flex">
            <v-col cols="3">
              <v-btn color="primary" @click="submit()">Salvar ofertas</v-btn>
            </v-col>
            <v-col cols="3"></v-col>
            <v-col cols="4"></v-col>
            <v-col cols="5">
              <v-btn class="mr-12" color="success" @click="add"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </v-col>
          </div>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { dateNow, dateFormat } from "../../utils/helpers/dateHelper";
import Authz from "../../mixins/authorized";
export default {
  mixins: [Authz],
  data() {
    return {
      fieldsValues: [
        {
          osSelected: null,
          areaSelected: null,
          dateSelected: dateNow(),
          periodSelected: null,
          quantidade: 1,
          usuario: null
        }
      ],

      name: "",
      service: [],
      area: null,
      menu1: false,
      form: {},
      dateMin: dateNow(),
      period: ["Manhã", "Tarde", "Noite"]
    };
  },
  created() {
    this.getAreas();
    this.getServiceItems();
  },
  watch: {
    profile: function(val) {
      this.fieldsValues[0].usuario = val.id;
    }
  },
  methods: {
    async getAreas() {
      try {
        const response = await this.$http.get("/area", this.fieldsValues);
        this.area = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getServiceItems() {
      try {
        const response = await this.$http.get("/service-items");
        this.service = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    dateformat(date) {
      return dateFormat(date);
    },
    add() {
      this.fieldsValues.push({
        dateSelected: dateNow(),
        quantidade: 1,
        usuario: this.profile.id
      });
    },
    remove(index) {
      this.fieldsValues.splice(index, 1);
    },

    async submit() {
      try {
        const response = await this.$http.post(
          "/offer/bulk",
          this.fieldsValues
        );

        if (response)
          return (
            this.$router.push("/oferta/listar") &&
            this.$toast.success("Cadastrado com sucesso!")
          );
      } catch (error) {
        if (error.response.status === 400)
          return this.$toast.error("Falha ao cadastrar, tente novamente!!!");
        return this.$toast.error("Tente novamente mais tarde!");
      }
    }
  }
};
</script>
<style></style>
